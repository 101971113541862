import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w02TA01Erlebnisse = () => (
  <Layout>
    <SEO title="w02TA01Erlebnisse" />

    <h1 id="ueberschrift">Tiere im Alltag</h1>
    <h2>Erlebnisse mit einem besonderen Partner &emsp;  ©&nbsp;1992</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w02TA01Erlebnisse&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem">
        "Tiere sind die besten Freunde. Sie stellen keine Fragen und kritisieren nicht."<br />
        <div style={{fontSize: "0.75em"}}>Samuel Langhorne Clemens (Mark Twain (1835 - 1910))</div>
      </p>

    <p>---</p>

      <p>
        Ob Fisch, Vogel, Hund, Katze, Hamster, Pferd, Kuh, etc. erzählen Sie 
        bei einem gemütlichen Beisammensein von Ihren Erlebnissen, Sorgen und Freuden mit Ihrem besonderen Partner
        und Freund. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen
        oder einfach bei lustigen und interessanten Anekdoten die Zeit genießen.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Wissensaustausch &amp; Ideenfindung
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz pro Teilnehmer + 1 Sitzplatz extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w02TA01Erlebnisse
